<template>
  <v-dialog
    v-model="dialog"
    width="860"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled='disabled' text @click="showDialog()" color='#005B4A'>
        <span class='hidden-sm-and-down font-weight-bold'>{{ $t('tokens.buttons.settle') }}</span>

        <v-icon color='#005B4A' size='20px'>
          mdi-arrow-right-box
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <!--   LOADING METAMASK   -->
      <OverlayLoadingMetamask :loading='loadings.send' />


        <v-card-title class="text--secondary d-flex justify-space-between align-center align-content-center"
                      style='font-size: 26px; font-weight: bold; color: #005b4a !important;'
        >
          <span class='text-uppercase'>
            {{ $t('tokens.titles.settleCredit') }}
            <v-icon right color='#005b4a' size='26px'>
              mdi-file-certificate
            </v-icon>
          </span>
          <div class='d-flex justify-start align-center'>
            <div class='d-flex justify-start align-center align-content-center'>
              <p class='ma-0 px-2 py-1 token-name '>
                {{ token.product }}
              </p>
              <p class='ma-0 px-2 py-1 token-address__default'>
                {{ token.symbol }}
              </p>
            </div>
          </div>
        </v-card-title>

        <v-card-text class='my-6'>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :footer-props="{
                  itemsPerPageText: $t('general.table.itemsPerPageText'),
                  pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
                }"
                :headers="headers"
                :items="lots"
                :loading="loading"
                :no-data-text="$t('general.ui.not_data_found')"
                :no-results-text="$t('general.ui.not_search_found')"
                :search="search"
                show-select
                v-model="selected"
                @item-selected="isSelected($event)"
                height="200px"
                single-select
              >
                <template v-slot:top>
                  <v-toolbar color="white" flat>
                    <v-row class="d-flex justify-space-between align-baseline pb-10">
                      <v-col class="pl-0" cols="6" lg="4" md="4" sm="6" xl="4">
                        <v-text-field
                            v-model="search"
                            :clearable="true"
                            :label="$t('general.titles.filter')"
                            append-icon="mdi-magnify"
                            color="primary"
                            dense
                            filled
                            hide-details
                            onkeypress="return (event.charCode != 34)"
                            rounded
                            single-line
                            item-key="id"
                        />
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="d-flex justify-end">
                        <strong>{{ $t('inventory.titles.totalToSend') }}
                          <v-chip small class="default">
                            {{ sum() }}
                          </v-chip>
                        </strong>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:item.locations="{ item }">
                  {{ item.locations.primary.name }} | {{ item.locations.secondary.name }}
                </template>
                <template v-slot:item.available="{ item }">
                  <v-chip small :class="amount(item) >= 0 ? 'success' : 'error'">
                    {{ amount(item) }}
                  </v-chip>
                </template>
                <template v-slot:item.amount="{ item }">
                  <v-form ref="formAmount" v-model="valid[item.id]" v-if="item.isSelected">
                    <v-text-field
                        class="ma-1"
                        :disabled="loading"
                        :placeholder="$t('tokens.fields.quantity')"
                        :hide-details="!(amounts[item.id] > item.available)"
                        :rules="validateMoreThan(0, item.available)"
                        :min="0"
                        @input="isEmpty(amounts[item.id], item.id)"
                        v-model="amounts[item.id]"
                        background-color="#EAEAEA80"
                        color="primary"
                        flat
                        small
                        solo
                        dense
                        type="number"
                    />
                  </v-form>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

      <v-card-actions class='card-actions__color darken-2 py-4'>
        <v-spacer></v-spacer>
        <v-btn
          color="#005B4A"
          text
          @click="closeDialog()"
          :disabled="loadings.send"
        >
          {{ $t('general.buttons.cancel') }}
        </v-btn>
        <ConfirmDialog :valid="!(lots.length > 0) || isValid || !moreThan || loadings.settle"
                       :loading="loadings.settle"
                       :btn-title="$t('tokens.buttons.settle')"
                       :is-tooltip="false"
                       :is-btn="true"
                       :is-icon="false"
                       btn-color="white"
                       action="settleTokens"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from 'vuex'
import ReadMore from "@/components/ReadMoreComponent";
import OverlayLoadingMetamask from '@/module/tokens/commons/OverlayLoadingMetamask'
import ConfirmDialog from '@/components/ConfirmDialog'

  export default {
    name: 'WalletSettleDialog',
    components: { ConfirmDialog, ReadMore, OverlayLoadingMetamask },
    props: {
      token: {
        type: Object,
        required: true,
        default: () => {
          return {}
        }
      },
      disabled: {
        type: Boolean,
        required: false,
        default: true
      }
    },

    data:() => ({
      loading: false,
      loadingLots: false,
      launchLoadingMetamask: false,
      dialog: false,
      wallet: null,
      search: null,
      selectToken: null,
      valid: {},
      selected: [],
      amounts: []
    }),

    mounted() {
      this.$root.$on("settleTokens", async () => {
        await this.settleTokens();
      });
    },

    computed: {
      ...mapGetters({
        wallets: 'web3/GET_WALLETS',
        requireRules: 'general/requireRules',
        onlyPositiveNumberFormat: 'general/onlyPositiveNumberFormat',
        validateAddress: 'general/ethereumAddress',
        validateMoreThan: 'general/validateMoreThan',
        selectRule: 'general/selectRule',
        loadings: 'web3/GET_LOADINGS'
      }),
      lots: {
        get() {
          return this.$store.getters['web3/GET_LOTS']
        },
        set(val) {
          this.$store.commit('web3/SET_LOTS', val)
        }
      },
      headers() {
        return [{
          text: i18n.t('inventory.titles.lots'), value: "lot", val: "lot"
        },{
          text: i18n.t('inventory.titles.locations'), value: "locations", val: "locations"
        },{
          text: i18n.t('inventory.titles.avilable'), value: "available", val: "available"
        },{
          text: i18n.t('inventory.titles.amount'), value: "amount", val: "amount", width: 200, align: "center"
        }]
      },
      amount() {
        return (item) => {
          let s = item.isSelected
          let a = item.available
          let i = this.amounts[item.id]

          if(s) {
            if(!i) return a
            else return (a-i)
          } {
            return item.available
          }
        }
      },
      isValid() {
        let count = 0
        _.forEach(this.valid, (v) => {
          if(!v) count ++
        })

        return (count > 0)
      },
      moreThan() {
        let total = 0
        _.forEach(this.amounts, (a) => {
          if(a)
            total += parseInt(a)
        })

        return total > 0
      }
    },

    methods: {
      ...mapActions({
        fetchLotsNFTs: 'web3/FETCH_LOTS_NFTs',
        fetchWallets: 'web3/FETCH_WALLETS',
        settleTokenTo: 'web3/OFFSET_CREDIT'
      }),
      async showDialog() {
        this.loadingLots = true

        await this.fetchWallets()

        await this.fetchLotsNFTs({
          action: "SETTLE",
          contractID: this.token.id,
          product: this.token.product,
        }).finally(() => {
          this.loadingLots = false
          this.dialog = true
        })
      },
      async settleTokens() {
        let _amounts = _.filter(this.amounts, a => { return !!a })
            _amounts = _amounts.map(a => parseInt(a))

        const _references = this.selected.map(s => parseInt(s.reference))
        const _lots = this.selected.map(l => l.lot)

        const data = {
          action: "SETTLE",
          contractId: this.token.id,
          contractAddress: this.token.contractAddress,
          product: this.token.product,
          lots: [{
            ...this.selected[0],
            amount: _amounts[0]
          }]
        }

        const payload = {
          data,
          wallet: this.wallet,
          amounts: _amounts,
          references: _references,
          description: _lots
        }

        await this.settleTokenTo(payload).finally(() => {
          this.$root.$emit('loadTokenData', this.token.contractAddress)
          this.closeDialog()
          this.dialog = false
        })
      },
      sum(value) {
        let amount = 0
        if(value) {
          _.forEach(value, (v) => {
            if(v) amount += parseInt(v)
          })
        } else {
          _.forEach(this.amounts, (a) => {
            if(a) amount += parseInt(a)
          })
        }

        return amount
      },
      isEmpty(value, index) {
        if(value === '' || value === null) {
          this.$set(this.amounts, index, 0)
        }
      },
      isSelected(event) {
        _.forEach(this.lots, (l) => {
          l.isSelected = false
        })
        this.amounts = []

        if(!event.value) {
          delete this.amounts[event.item.id]
        }

        _.find(this.lots, { id: event.item.id }).isSelected = event.value

        setTimeout(() => {
          this.amounts = this.amounts.map(a => parseInt(a)).filter(a => !!a)
          this.sum(this.amounts)
        }, 200)
      },
      clearForm() {
        this.amounts = []
        if(this.selected && this.selected.length > 0) {
          _.forEach(this.selected, (l) => {
            l.isSelected = false
          })
          this.$refs.formAmount.resetValidation()
          this.selected = []
        }
        _.forEach(this.lots, (l) => {
          l.isSelected = false
        })
      },
      closeDialog() {
        this.loading = false
        this.dialog = false
        this.loadings.settle = false
        this.clearForm()
      }
    },

    destroyed() {
      this.$root.$off('settleTokens')
    }
  }
</script>

<style scoped>
  .v-dialog:not(.v-dialog--fullscreen) {
    border-radius: 1em;
  }

  .theme--light.v-label {
    color: #232323 !important;
    margin-left: 4px
  }

  .token-name {
    background: #f3f3f3 !important;
    color: #005B4A !important;
    border-radius: .3em 0 0 .3em !important;
    font-size: 12px !important;
    font-weight: bold !important;
  }

  .token-address__default {
    border-radius: 0 .3em .3em 0 !important;
    font-size: 12px !important;
    background: #005B4A !important;
    color: white !important;
    font-weight: bold !important;
  }

  .card-actions__color {
    background-color: #03AC8C !important;
  }

  >>>.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none !important;
  }

  .token-address__available {
    border-radius: 0 .3em .3em 0;
    font-size: 12px;
    font-weight: 500;
    background: rgb(3 172 140 / 7%);
    color: rgb(15 155 128 / 70%);
  }

  .v-chip.success {
    border-radius: .3em !important;
    background-color: rgb(117 227 81 / 24%) !important;
    color: seagreen !important;
    font-weight: 800;
  }

  .v-chip.error {
    border-radius: 0.3em !important;
    background-color: rgb(231 11 11 / 15%) !important;
    color: rgb(245 30 30) !important;
    font-weight: 600;
  }

  .v-chip.default {
    border-radius: 0.3em !important;
    font-weight: 600;
  }
</style>
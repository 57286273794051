<template>
  <v-card class='card__wallet' color='primary' elevation='0'>
    <v-card-text>
      <v-row align-content='start' justify='space-between' v-if='token'>
        <v-col class='py-0 d-flex justify-space-between align-start align-content-center' cols='11'>
          <v-list color='transparent' elevation='0'>
            <v-list-item>
              <v-list-item-title>
                <div class='d-flex justify-start align-center'>
                  <p v-if='!show' class='my-0 py-0 title__credit-card d-flex justify-start align-center'>
                    {{ wallet.balance }}
                    <span class='suffix__credit-card'>{{ token.symbol }}</span>
                  </p>
                  <div v-else class='d-flex justify-start align-center align-content-center'>
                    <hr class='line__hide-credit mr-2'>
                    <p class='my-0 py-0 title__credit-card'>
                      <span class='suffix__credit-card'>{{ token.symbol }}</span>
                    </p>
                  </div>
                  <v-icon right :class='[{ "synced-on__icon rotating" : isSync }, { "synced-off__icon" : !isSync }]'>
                    mdi-autorenew
                  </v-icon>
                </div>
                <div class='d-flex justify-start align-center'>
                  <div class='d-flex justify-start align-center align-content-center'>
                    <p class='ma-0 px-2 py-1 token-name '>
                      {{ token.product | truncate(12) }}
                    </p>
                    <div>
                      <p class='ma-0 px-2 py-1 token-address__default'>
                        {{ token.symbol }}
                      </p>
                    </div>
                  </div>
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class='mt-12 d-flex justify-start align-end'>
              <div class='d-flex justify-start align-content-center align-center'>
                <v-icon class='icon__wallet-address'>mdi-identifier</v-icon>
                <p class='title__wallet-address py-0 my-0'>{{ token.contractAddress | truncateAddress(6) }}</p>
              </div>
            </v-list-item>
          </v-list>
          <v-icon class='my-4 icon__show-credit' @click='show = !show'>
            {{ show ? 'mdi-eye' : 'mdi-eye-off'}}
          </v-icon>
        </v-col>
      </v-row>
      <v-row class='d-flex justify-center align-content-center align-center' v-else>
        <v-col cols='12' class='d-flex justify-center align-center align-content-center' style='height: 18vh'>
          <p class='text-center ma-0 pa-0 white--text font-weight-bold text-uppercase'>{{
              $t('tokens.titles.selectTokenFirst')
          }}</p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "WalletCardComponent",

  props: {
    token: {
      type: Object,
      required: false
    },
    wallet: {
      type: Object,
      required: false
    }
  },

  data () {
    return {
      show: false
    }
  },

  computed: {
    ...mapGetters({
      isSync: 'web3/GET_IS_SYNC'
    })
  }
}
</script>

<style scoped lang="scss">
  .card__wallet {
    background:url("~@/assets/img/Logo-AgTrace.png") var(--v-primary-darken2) 173%;
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: 43%;
    background-position: 137% 15%;
  }

  .title__credit-card {
    font-size: 30px;
    font-family: "Avenir Heavy", sans-serif;
    color: white;
  }

  .suffix__credit-card {
    color: #1d1d1d;
  }

  .icon__show-credit {
    cursor: pointer !important;
  }

  .line__hide-credit {
    border: 1px solid white;
    width: 100px;
  }

  .token-name {
    background: white;
    color: #1d1d1d;
    border-radius: .3em 0 0 .3em;
    font-size: 12px;
    font-weight: bold;
  }

  .token-address__default {
    border-radius: 0 .3em .3em 0;
    font-size: 12px;
    background: var(--v-primary-darken2);
    color: white;
    font-weight: bold;
  }

  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) i.icon__wallet-address {
    font-size: 28px;
    color: rgba(0, 0, 0, 0.5) !important;
  }

  .title__wallet-address {
    font-family: "Avenir Heavy", sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
  }

  :deep(.synced-on__icon) {
    color: var(--v-primary-base) !important;
  }

  :deep(.synced-off__icon) {
    color: var(--v-primary-darken4) !important;
  }
</style>